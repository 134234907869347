<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="10"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewContent"
          >
            <feather-icon 
              icon="PlusIcon" 
              svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add Group</span>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="group_name">Group name</vs-th>
          <vs-th sort-key="updated_at">Updated at</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.group_name }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <router-link
                    :to="{ name: `user-group-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link">
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    v-if="!tr.is_default"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                  <!-- <a href
                     @click.prevent="deleteData(tr.id)"
                     class="hover:text-danger">
                    <feather-icon
                      icon="TrashIcon"
                      svg-classes="w-5 h-5 stroke-current"
                    />
                  </a> -->
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import useDatepicker from '@/use/useDatepicker'
import useNotify from '@/use/useNotify'
import useCrud from '@/use/useCrud'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'UserGroupListView',
  components: { CustomTable },
  setup(props, ctx) {
    const { root: { $store, $vs } } = ctx
    const { error: notifyError } = useNotify(ctx)
    const { updatedAtDatetimeFormat } = useDatepicker()
    const storeName = 'users'

    const crudFunction = useCrud(ctx, 'user-group')
    crudFunction.routePrefix.value = 'user-group'

    onMounted(() => {
      $store.dispatch(`${storeName}/fetchUserGroup`)
    })

    const items = computed(() => {
      return $store.state.users.groups
    })

    const addNewContent = () => {
      ctx.root.$router.push({
        name: 'user-group-create',
      })
    }

    const updateData = (id) => {
      ctx.root.$router.push({
        name: 'user-group-edit',
        params: { id },
      })
    }

    const deleteData = (id) => {
      $vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'ต้องการลบข้อมูลหรือไม่',
        acceptText: 'ตกลง',
        cancelText: 'ยกเลิก',
        accept: () => {
          $store
            .dispatch(`users/deleteUserGroup`, id)
            .then(() => {
              $store.dispatch(`users/fetchUserGroup`)

              notifyError({
                title: 'ลบข้อมูล',
                text: 'ลบข้อมูลเรียบร้อยแล้ว',
              })
            })
            .catch((error) => {
              console.log('error: ', error.message)
              if (
                error.message.indexOf('foreign key constraint "user_group_id_fkey"') > -1
              ) {
                notifyError({
                  text:
                    'ไม่สามารถลบข้อมูลได้ เนื่องจากมีผู้ใช้ระบบอยู่ในกลุ่ม',
                })
              } if (error.message.indexOf('foreign key constraint "project_group_id_fkey"') > -1) {
                notifyError({
                  text:
                    'ไม่สามารถลบข้อมูลได้ เนื่องจากมีโครงการอยู่ในกลุ่มที่เลือก',
                })
              } else {
                notifyError({ text: JSON.stringify(error) })
              }
            })
        },
      })
    }

    return {
      items,
      addNewContent,
      updateData,
      deleteData,
      updatedAtDatetimeFormat,
      editData: crudFunction.editData,
      searchKeys: ['group_name']
    }
  },
}
</script>

<style scoped></style>
